@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
    @apply h-full w-full box-border antialiased;
    text-rendering: optimizeLegibility;
}

form input {
    @apply border rounded-sm p-2 flex-1;
}

input[type='submit'] {
    @apply bg-tibi-primary border text-white;
}

#root {
    @apply flex flex-col h-full;
}

nav ul li a {
    &::before {
        content: '';
        @apply block bg-transparent absolute w-[3px] h-full left-0 top-0 bottom-0;
    }
    &.activeNavLink::before {
        @apply bg-tibi-primary;
    }
}

.newDelivery {
    &::before {
        content: '';
        @apply block bg-tibi-primary absolute w-[3px] h-full left-0 top-0 bottom-0;
    }
    &::after {
        content: '';
        @apply block bg-gradient-to-r from-tibi-primary/5 to-tibi-fadedPrimary/0 absolute w-1/3 h-full left-0 top-0 bottom-0;
    }
}

.react-tooltip {
    --rt-opacity: 1;
    @apply border;
    border-radius: 0.5rem !important;
}

input[type='text'],
input[type='search'] {
    @apply py-1.5 text-base px-3 outline-none rounded;
}

.message-block {
    a {
        @apply text-tibi-linkColor font-semibold underline;
    }
}

.message-block.left-top:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -10px;
    right: auto;
    top: 0px;
    bottom: auto;
    z-index: 2;
    border: 12px solid;
    border-color: white transparent transparent transparent;
}
.message-block.left-top:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -12px;
    right: auto;
    top: -1px;
    z-index: 1;
    bottom: auto;
    border: 12px solid;
    border-color: #e5e7eb transparent transparent transparent;
}

.message-block.right-top:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -12px;
    top: 0px;
    bottom: auto;
    border: 12px solid;
    border-color: #b2e2df transparent transparent transparent;
}
